<template>
  <div class="menu-wrapper">

    <div class="f fcol fs ft">
      <span class="fw600 fz14 hm">CSMC</span>

      <ul class="footer-menu mobile-f">
        <li class="menu-item">
          <!--      <span class="item-title">CS2 {{ t('markets') }}</span>-->
          <ul class="item-links">
            <li>
              <NuxtLink :to="localePath('/')">
                <span class="link-title">{{ t('main') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                  :to="{path: localePath('/comparison'), query: {}}"
              >
                <span class="link-title">{{ t('price_comparison') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/discord')">
                <span class="link-title"> {{ t('discord_bot') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/blog')">
                <span class="link-title"> {{ t('blog') }}</span>
              </NuxtLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="f fcol fs ft">
      <span class="fw600 fz14">{{ t('items').toUpperCase() }}</span>
      <ul class="footer-menu">
        <li class="menu-item">
          <ul class="item-links">
            <li>
              <NuxtLink :to="localePath('/weapons/agents')">
                <span class="link-title">{{ t('filters.weapon.Agents') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/case')">
                <span class="link-title">{{ t('filters.weapon.Case') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/weapon-charms')">
                <span class="link-title">{{ t('filters.weapon.Charms') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/graffities')">
                <span class="link-title">{{ t('filters.weapon.Graffities') }}</span>
              </NuxtLink>
            </li>
          </ul>
        </li>
        <li class="menu-item">
          <ul class="item-links">
            <li>
              <NuxtLink :to="localePath('/weapons/all/gloves')">
                <span class="link-title">{{ t('filters.weapon.Gloves') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/all/heavy')">
                <span class="link-title">{{ t('filters.weapon.Heavy') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/all/knives')">
                <span class="link-title">{{ t('filters.weapon.Knives') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/all/pistols')">
                <span class="link-title">{{ t('filters.weapon.Pistols') }}</span>
              </NuxtLink>
            </li>
            <!--        <li>-->
            <!--          <NuxtLink :to="localePath('/weapons/music-kits')">-->
            <!--            <span class="link-title">{{ t('filters.weapon.Music Kits') }}</span>-->
            <!--          </NuxtLink>-->
            <!--        </li>-->
          </ul>
        </li>
        <li class="menu-item">
          <ul class="item-links">
            <li>
              <NuxtLink :to="localePath('/weapons/all/rifles')">
                <span class="link-title">{{ t('filters.weapon.Rifles') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/all/smgs')">
                <span class="link-title">{{ t('filters.weapon.SMGs') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/keys')">
                <span class="link-title">{{ t('filters.weapon.Keys') }}</span>
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="localePath('/weapons/stickers')">
                <span class="link-title">{{ t('filters.weapon.Stickers') }}</span>
              </NuxtLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {useLocalePath} from "#i18n";

const localePath = useLocalePath()

const {t, locale} = useI18n()
</script>

<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.menu-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 56px;
}

.footer-menu {
  display: flex;
  gap: 56px;

  .menu-item {
    min-height: 140px;

    .item-title {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $lighten;
    }

    .item-links {
      margin-top: 2.05rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem;
    }

    .link-title {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $light;

      &:hover {
        color: $lighten;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .menu-wrapper {
    flex-direction: column;
    gap: 32px;
  }

  .footer-menu {
    //  flex-direction: column;
    gap: 40px;

    .menu-item {
      min-height: unset;
    }
  }
  .mobile-f .menu-item .item-links {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px 28px;
  }
}
</style>
